import React, { Component } from 'react';
import { get } from '../../api/api';
import PageSpinner from 'components/Spinner/PageSpinner';
import PodcastList from 'components/PodcastList/PodcastList';
import Header from 'components/Header/Header';
import Navigation from 'navigation/navigation';
import LanguageSelector from 'components/LanguageSelector/LanguageSelector';
import Style from './discover.module.css';
import { logEventDiscoverTab } from 'utils/analytics';

class Discover extends Component {

    constructor(props) {
        super(props);
        const { selectedLanguage } = this.props.location.state || {};
        this.state = {
            user: null,
            podcastFeed: [],
            podcastByLanguageFeed: [],
            languages: [],
            activeTab: 0,
            showSelectLanguageButton: true,
            showLanguageSelector: false,
            selectedLanguage: selectedLanguage || { _id: 'hi', name: 'Hindi (हिन्दी)' },
            loading: true,
            nextLoading: false,
            previousLoading: false,
            nextSkip: 0,
            nextPage: true,
            loadingText: '',
            loadingAnimation: null,
            successMessage: '',
            errMessage: ''
        };
        this.nextButtonRef = React.createRef();
        this.previousButtonRef = React.createRef();
    }

    async componentDidMount() {
        this.loadOfflineData();
        await this.getUser();
        document.addEventListener('keydown', this.onKeyDown);
        this.unblock = this.props.history.block((location, action) => {
            return action === 'POP' ? window.close() : undefined;
        });
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onKeyDown);
        if (this.unblock) {
            this.unblock();
        }
    }

    getUser = async () => {
        try {
            const response = await get('/user/me');
            const { user } = response;
            if (user) {
                this.setState({ user: user });
            }
            await this.getTopTenPodcasts();
            await this.getPodcastByLanguage();
            await this.getLanguages();
        } catch (error) {
        }
    };

    getTopTenPodcasts = async () => {
        try {
            window.scrollTo({ bottom: 0, behavior: 'smooth' });
            const response = await get('/podcasts/topTen', { page: 'discover' });
            const { podcasts, nextSkip, nextPage } = response;
            let allPodcasts = [...this.state.podcastFeed, ...podcasts].filter((podcast, index, self) =>
                index === self.findIndex((checkPodcast) => checkPodcast.rssURL === podcast.rssURL)
            );
            if (this.state.nextPage === 0 && allPodcasts.length > 0) {
                allPodcasts = allPodcasts.slice(0, Math.min(50, allPodcasts.length));
                localStorage.setItem('topThreePodcastsData', JSON.stringify(allPodcasts));
            }
            this.setState({
                podcastFeed: podcasts.length ? podcasts : allPodcasts,
                loading: false,
                nextSkip: nextSkip,
                nextPage: nextPage,
                nextLoading: false,
                previousLoading: false,
                loadingText: ''
            });
            if (this.loadingAnimation) {
                clearInterval(this.loadingAnimation);
            }
            logEventDiscoverTab(this.user);
        } catch (error) {
            this.loadOfflineData();
            this.setState((prevState) => ({
                loading: false,
                nextLoading: false,
                previousLoading: false,
                loadingText: '',
                errMessage: error || 'Something Went Wrong'
            }));
        }
    };


    handleOnClickSelectLanguage = async () => {
        this.setState({ showSelectLanguageButton: false, showLanguageSelector: true });
    };

    handleSelectedLanguage = (selectedLanguage) => {
        if (!selectedLanguage) {
            return;
        }
        this.setState({ showSelectLanguageButton: true, showLanguageSelector: false, selectedLanguage: selectedLanguage }, () => {
            this.getPodcastByLanguage();
        });
    };

    getPodcastByLanguage = async () => {
        try {
            if (!this.state.selectedLanguage) {
                return;
            }
            window.scrollTo({ bottom: 0, behavior: 'smooth' });
            const response = await get('/podcasts/getAllByLanguage', { language: this.state.selectedLanguage._id || 'en' });
            const { podcasts } = response;
            this.setState({ podcastByLanguageFeed: podcasts });
        } catch (error) {
            this.loadOfflineData();
            this.setState((prevState) => ({
            }));
        }
    };

    getLanguages = async () => {
        try {
            const response = await get('/podcasts/languages');
            const { languages } = response;
            this.setState({ languages: languages });
        } catch (error) {
        }
    };

    loadOfflineData = () => {
        const topThreePodcastsData = localStorage.getItem('topThreePodcastsData');
        const podcastFeeds = topThreePodcastsData ? JSON.parse(topThreePodcastsData) : null;
        if (podcastFeeds) {
            this.setState({
                podcastFeed: podcastFeeds,
                errMessage: ''
            });
        }
    };

    updateLoadingText = () => {
        this.setState((prevState) => {
            const newText = prevState.loadingText === '...' ? '.' : prevState.loadingText + '.';
            return { loadingText: newText };
        });
    };

    onClickViewAllTopTen = async () => {
        this.props.history.push('/home/topTenPodcasts');
    };

    onClickViewAllByLanguage = async () => {
        this.props.history.push({
            pathname: '/home/podcastsByLanguage',
            state: { selectedLanguage: this.state.selectedLanguage }
        });
    };

    scrollToCardById = (id) => {
        const divElement = document.getElementById(`podcastCard-${id}`);
        if (divElement) {
            const rect = divElement.getBoundingClientRect();
            const isInViewPort = rect.top >= 0 && rect.bottom <= window.innerHeight;
            if (!isInViewPort) {
                setTimeout(() => {
                    window.scroll({
                        top: divElement.offsetTop,
                        left: 0,
                        behavior: 'smooth'
                    });
                });
            }
        }
    };

    onKeyDown = (e) => {
        if (!e.key) return;

        this.setState((prevState) => {
            let { activeTab } = prevState;
            switch (e.key) {
                case 'SoftRight':
                case 'Backspace':
                    window.close();
                    break;
                default:
                    return;
            }
            return { activeTab };
        });
    };

    render() {
        const { podcastFeed, podcastByLanguageFeed, activeTab, languages, selectedLanguage, showSelectLanguageButton, showLanguageSelector, loading, successMessage, errMessage } = this.state;
        return (
            <div className={Style.container}>
                <Header title='Discover' showSelectLanguageButton={showSelectLanguageButton} onClickSelectLanguageLink={this.handleOnClickSelectLanguage} />
                {loading ? (
                    <PageSpinner />
                ) : (
                    <React.Fragment>
                        {
                            !showLanguageSelector &&
                            <React.Fragment>
                                {successMessage.length ? <span style={{ marginTop: 10, color: 'green', fontSize: '0.8em' }}>{successMessage}</span> : null}
                                {errMessage.length ? <span style={{ marginTop: 10, color: 'red', fontSize: '0.8em' }}>{errMessage}</span> : null}
                                <div className={Style.divTitleSection}>
                                    <div className={Style.divTitleRow}>
                                        Top 10
                                    </div>
                                </div>
                                <PodcastList data={podcastFeed} loading={loading} activeElement={activeTab} />
                                <div style={{ height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <span className={Style.button} style={{ backgroundColor: activeTab === 4 ? '#c4b5c9' : '#fff', cursor: 'pointer', marginRight: '5px' }} onClick={this.onClickViewAllTopTen}>
                                        View All
                                    </span>
                                </div>
                                <div style={{ margin: '5px', border: '1px solid #eae7e7' }}></div>
                                <div className={Style.divTitleSection}>
                                    <div className={Style.divTitleRow}>Podcast By Language</div>
                                    <div className={Style.divSelectedLanguageRow}>
                                        {selectedLanguage ? selectedLanguage.name : ''}
                                    </div>
                                </div>
                                <PodcastList data={podcastByLanguageFeed} loading={loading} activeElement={activeTab - 5} />
                                {
                                    podcastByLanguageFeed.length > 0 &&
                                    <div style={{ height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <span className={Style.button} style={{ backgroundColor: activeTab === 9 ? '#c4b5c9' : '#fff', cursor: 'pointer', marginRight: '5px' }} onClick={this.onClickViewAllByLanguage}>
                                            View All
                                        </span>
                                    </div>
                                }
                                <div style={{ height: '100px' }}></div>
                                <Navigation
                                    active='Discover'

                                />
                            </React.Fragment>
                        }
                        {
                            showLanguageSelector &&
                            <LanguageSelector
                                languages={languages}
                                defaultLanguage={selectedLanguage}
                                onLanguageSelect={this.handleSelectedLanguage}
                            />
                        }
                    </React.Fragment>
                )}
            </div>
        );
    }
}

export default Discover;