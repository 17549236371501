import React from 'react';
import PodcastCard from 'components/PodcastCard/PodcastCard';
import Style from './PodcastList.module.css';
import PageSpinner from 'components/Spinner/PageSpinner';

const PodcastList = ({ data, loading, activeElement, canFocus = true }) => {
    return (
        <div className={Style.podcastContainer}>
            {data.length === 0 && loading && <PageSpinner />}
            {data.length === 0 && !loading && <span className={Style.title} style={{ marginTop: '10px', marginBottom: '10px' }}>No Podcasts</span>}
            {data.length > 0 && data.map((podcast, index) => (<PodcastCard data={podcast} key={index} activeElement={activeElement} index={index} canFocus={canFocus} />))}
        </div>
    );
};

export default PodcastList;
