import React, { Component } from 'react';
import { get } from '../../api/api';
import PageSpinner from 'components/Spinner/PageSpinner';
import PodcastList from 'components/PodcastList/PodcastList';
import Header from 'components/Header/Header';
import Navigation from 'navigation/navigation';
import Style from './TopTenPodcasts.module.css';
import { logEventTopTenPodcastsTab } from 'utils/analytics';

class TopTenPodcasts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      podcastFeed: [],
      activeTab: 0,
      loading: true,
      loadingText: '',
      loadingAnimation: null,
      successMessage: '',
      errMessage: ''
    };
  }

  async componentDidMount() {
    this.loadOfflineData();
    await this.getUser();
    document.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
  }

  backHandler = () => {
    this.props.history.push('/discover');
  };

  getUser = async () => {
    try {
      const response = await get('/user/me');
      const { user } = response;
      if (user) {
        this.setState({ user: user });
      }
      await this.getData();
    } catch (error) {
    }
  };

  getData = async () => {
    try {
      window.scrollTo({ bottom: 0, behavior: 'smooth' });
      const response = await get('/podcasts/topTen', { page: 'topten' });
      const { podcasts } = response;
      let allPodcasts = [...this.state.podcastFeed, ...podcasts].filter((podcast, index, self) =>
        index === self.findIndex((checkPodcast) => checkPodcast.rssURL === podcast.rssURL)
      );
      if (allPodcasts.length > 0) {
        localStorage.setItem('topTenPodcastsData', JSON.stringify(allPodcasts));
      }
      this.setState({
        podcastFeed: podcasts.length ? podcasts : allPodcasts,
        loading: false,
        loadingText: ''
      });
      if (this.loadingAnimation) {
        clearInterval(this.loadingAnimation);
      }
      logEventTopTenPodcastsTab(this.user);
    } catch (error) {
      this.loadOfflineData();
      this.setState((prevState) => ({
        loading: false,
        loadingText: '',
        errMessage: error || 'Something Went Wrong'
      }));
    }
  };

  loadOfflineData = () => {
    const topTenPodcastsData = localStorage.getItem('topTenPodcastsData');
    const podcastFeeds = topTenPodcastsData ? JSON.parse(topTenPodcastsData) : null;
    if (podcastFeeds) {
      this.setState({
        podcastFeed: podcastFeeds,
        errMessage: ''
      });
    }
  };

  updateLoadingText = () => {
    this.setState((prevState) => {
      const newText = prevState.loadingText === '...' ? '.' : prevState.loadingText + '.';
      return { loadingText: newText };
    });
  };

  scrollToCardById = (id) => {
    const divElement = document.getElementById(`podcastCard-${id}`);
    if (divElement) {
      const rect = divElement.getBoundingClientRect();
      const isInViewPort = rect.top >= 0 && rect.bottom <= window.innerHeight;
      if (!isInViewPort) {
        const targetScrollTop = divElement.offsetTop - (window.innerHeight * 0.5) + (rect.height / 2);
        setTimeout(() => {
          window.scroll({
            top: targetScrollTop,
            left: 0,
            behavior: 'smooth'
          });
        });
      }
    }
  };

  onKeyDown = (e) => {
    return;
    if (!e.key) {
      return;
    }
    const { activeTab, podcastFeed } = this.state;
    const maxIndex = podcastFeed.length - 1;
    if (e.key === 'ArrowRight') {
      if (activeTab % 2 === 0 && activeTab < maxIndex) {
        this.setState({ activeTab: activeTab + 1 }, () => {
          this.scrollToCardById(this.state.activeTab);
        });
      }
    } else if (e.key === 'ArrowLeft') {
      if (activeTab % 2 !== 0) {
        this.setState({ activeTab: activeTab - 1 }, () => {
          this.scrollToCardById(this.state.activeTab);
        });
      }
    } else if (e.key === 'ArrowDown') {
      if (activeTab < maxIndex) {
        this.setState((prev) => {
          const newIndex = Math.min(prev.activeTab + 2, maxIndex);
          setTimeout(() => { this.scrollToCardById(newIndex); });
          return { activeTab: newIndex };
        });
      }
    } else if (e.key === 'ArrowUp') {
      if (activeTab > 0) {
        let tab = activeTab - 2;
        if (tab < 0) tab = 0;
        this.setState({ activeTab: tab }, () => {
          this.scrollToCardById(this.state.activeTab);
        });
      }
    } else if (e.key === 'SoftLeft' || e.key === 'Enter') {
      const data = this.state.podcastFeed[this.state.activeTab];
      this.props.history.push(`/podcastdetail/${data._id}?`);
    } else if (e.key === 'SoftRight' || e.key === 'Backspace') {
      this.backHandler();
    }
  };

  render() {
    const { podcastFeed, activeTab, loading, successMessage, errMessage } = this.state;
    return (
      <div>
        <Header title='Top 10' back={true} backHandler={this.backHandler} />
        {loading ? (
          <PageSpinner />
        ) : (
          <>
            {successMessage.length ? <span style={{ marginTop: 10, color: 'green', fontSize: '0.8rem' }}>{successMessage}</span> : <></>}
            {errMessage.length ? <span style={{ marginTop: 10, color: 'red', fontSize: '0.8rem' }}>{errMessage}</span> : <></>}
            <div className={Style.topTenContainer}>
              Top 10
            </div>
            <PodcastList data={podcastFeed} loading={loading} activeElement={activeTab} />
            <div style={{ height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            </div>
          </>
        )}
        <div style={{ height: '60px' }}></div>
        <Navigation active='Discover' />
      </div>
    );
  }
}

export default TopTenPodcasts;
