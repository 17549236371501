import Style from 'components/MenuButton/menuButtonStyles.module.css';

function MenuButton({ children, index, buttonText, iconBgColor, iconColor, onClick, isFocus, showArrow = true, cursorStyle = 'pointer' }) {
    return (
        <div id={`menuButton-${index}`} className={Style.MenuButton} onClick={onClick} style={{ cursor: cursorStyle }}>
            <div className={Style.MenuButtonIcon} style={{ color: iconColor, backgroundColor: iconBgColor }}>
                {children}
            </div>
            <div style={{ fontSize: "0.8rem" }}>{buttonText}</div>
            {
                showArrow &&
                <div className={Style.MenuButtonArrow}>{'>'}</div>
            }
        </div>
    );
}

export default MenuButton;