import React, { Component } from 'react';
import { get } from '../../api/api';
import Style from 'components/Header/headerStyles.module.css';
import { withRouter } from 'react-router-dom';
import logo from 'assets/logo_white.png';

class Header extends Component {
    constructor(props) {
        super(props);
        const { selectedLanguage } = this.props.location.state || {};
        this.state = {
            languages: [],
            showLanguageSelector: false,
            selectedLanguage: selectedLanguage || { _id: 'hi', name: 'Hindi (हिन्दी)' },
            isClicked: false,
        };
        this.refreshHandler = this.refreshHandler.bind(this);
        this.handleGoToHome = this.handleGoToHome.bind(this);
    }

    componentDidMount() {
        document.addEventListener('keydown', this.onKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onKeyDown);
    }

    onKeyDown = (e) => { };

    onClickSelectLanguageButton = () => {
        this.setState({ isClicked: true });
        setTimeout(() => {
            this.setState({ isClicked: false });
        }, 1000);
        this.props.onClickSelectLanguageLink(this.isClicked);
    };

    async getLanguages() {
        try {
            const response = await get('/podcasts/languages');
            const { languages } = response;
            this.setState({ languages });
        } catch (error) {
        }
    }

    refreshHandler() {
        window.location.reload();
    }

    handleGoToHome() {
        const { history } = this.props;
        history.goBack();
    }

    render() {
        const { title, showSelectLanguageButton, refresh = false, back = false, backHandler = false } = this.props;

        return (
            <div className={Style.HeaderLayout}>
                <div
                    className={`container ${Style.HeaderContainer}`}
                    style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {back && backHandler ? (
                            <div
                                className={Style.HeaderIcons}
                                onClick={backHandler}
                                style={{ display: 'flex', alignItems: 'center' }}
                            >
                                <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    strokeWidth="0"
                                    viewBox="0 0 512 512"
                                    height="1.5em"
                                    width="1.5em"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M401.4 224h-214l83-79.4c11.9-12.5 11.9-32.7 0-45.2s-31.2-12.5-43.2 0L89 233.4c-6 5.8-9 13.7-9 22.4v.4c0 8.7 3 16.6 9 22.4l138.1 134c12 12.5 31.3 12.5 43.2 0 11.9-12.5 11.9-32.7 0-45.2l-83-79.4h214c16.9 0 30.6-14.3 30.6-32 .1-18-13.6-32-30.5-32z"></path>
                                </svg>
                                <p
                                    className={Style.HeaderTitle}
                                    style={{ margin: '0', fontSize: '12px', paddingLeft: '4px' }}
                                >
                                    {title}
                                </p>
                            </div>
                        ) : (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img
                                    className={Style.HeaderLogo}
                                    src={logo}
                                    alt="HeaderLogo"
                                    onClick={this.handleGoToHome}
                                    style={{ cursor: 'pointer', height: '24px' }}
                                />
                                <p
                                    className={Style.HeaderTitle}
                                    style={{ margin: '0', fontSize: '12px', paddingLeft: '4px' }}
                                >
                                    {title}
                                </p>
                            </div>
                        )}
                        {refresh && (
                            <div
                                className={Style.HeaderIcons}
                                onClick={this.refreshHandler}
                                style={{ marginLeft: '8px', display: 'flex', alignItems: 'center' }}
                            >
                                <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    strokeWidth="0"
                                    viewBox="0 0 512 512"
                                    height="1.5em"
                                    width="1.5em"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M256 388c-72.597 0-132-59.405-132-132 0-72.601 59.403-132 132-132 36.3 0 69.299 15.4 92.406 39.601L278 234h154V80l-51.698 51.702C348.406 99.798 304.406 80 256 80c-96.797 0-176 79.203-176 176s78.094 176 176 176c81.045 0 148.287-54.134 169.401-128H378.85c-18.745 49.561-67.138 84-122.85 84z"></path>
                                </svg>
                            </div>
                        )}
                    </div>

                    {showSelectLanguageButton && (
                        <div>
                            <span
                                className={Style.button}
                                style={{
                                    padding: '3px',
                                    backgroundColor: this.state.isClicked ? '#c4b5c9' : '#fff',
                                    cursor: 'pointer',
                                    fontSize: '10px',
                                }}
                                onClick={this.onClickSelectLanguageButton}
                            >
                                Select Language
                            </span>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default withRouter(Header);
