import React, { Component } from 'react';
import { get } from '../../api/api';
import PageSpinner from 'components/Spinner/PageSpinner';
import PodcastList from 'components/PodcastList/PodcastList';
import Header from 'components/Header/Header';
import Navigation from 'navigation/navigation';
import Style from './podcastsByLanguage.js.module.css';
import { logEventPodcastsByLanguageTab } from 'utils/analytics';

class PodcastsByLanguage extends Component {
  constructor(props) {
    super(props);
    const { selectedLanguage } = this.props.location.state || {};

    this.state = {
      user: null,
      podcastFeed: [],
      selectedLanguage: selectedLanguage || { _id: 'hi', name: 'Hindi (हिन्दी)' },
      activeTab: 0,
      loading: true,
      nextLoading: false,
      previousLoading: false,
      nextSkip: 0,
      nextPage: true,
      loadingText: '',
      successMessage: '',
      errMessage: '',
      focusNext: false,
      focusPrevious: false
    };

    this.nextButtonRef = React.createRef();
    this.previousButtonRef = React.createRef();
  }

  async componentDidMount() {
    this.loadOfflineData();
    await this.getUser();
    document.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
  }

  getUser = async () => {
    try {
      const response = await get('/user/me');
      const { user } = response;
      if (user) {
        this.setState({ user: user });
      }
      await this.getData();
    } catch (error) {
    }
  };

  backHandler = () => {
    this.props.history.push({
      pathname: '/discover',
      state: { selectedLanguage: this.state.selectedLanguage }
    });
  };

  getData = async (isPrevious = false) => {
    try {
      let skip = this.state.nextSkip;
      if (isPrevious) {
        skip = (skip === 50) ? 0 : skip - 100;
      }
      window.scrollTo({ bottom: 0, behavior: 'smooth' });
      const { selectedLanguage } = this.state;
      const param = {
        language: selectedLanguage ? selectedLanguage._id : 'en',
        query: 'all',
        skip: skip
      };
      const response = await get('/podcasts/getAllByLanguage', param);
      const { podcasts, nextSkip, nextPage } = response;
      let allPodcasts = [...this.state.podcastFeed, ...podcasts].filter((podcast, index, self) =>
        index === self.findIndex((checkPodcast) => checkPodcast.rssURL === podcast.rssURL)
      );
      if (this.state.nextPage === 0 && allPodcasts.length > 0) {
        allPodcasts = allPodcasts.slice(0, Math.min(50, allPodcasts.length));
        localStorage.setItem('podcastsByLanguageData', JSON.stringify(allPodcasts));
      }
      this.setState({
        podcastFeed: podcasts.length ? podcasts : allPodcasts,
        loading: false,
        nextSkip: nextSkip,
        nextPage: nextPage,
        nextLoading: false,
        previousLoading: false,
        loadingText: ''
      });
      logEventPodcastsByLanguageTab(this.state.user, { Language: this.state.selectedLanguage });
    } catch (error) {
      this.loadOfflineData();
      this.setState((prevState) => ({
        loading: false,
        nextLoading: false,
        previousLoading: false,
        loadingText: '',
        errMessage: error || 'Something Went Wrong'
      }));
    }
  };

  loadOfflineData = () => {
    const podcastsByLanguageData = localStorage.getItem('podcastsByLanguageData');
    const podcastFeeds = podcastsByLanguageData ? JSON.parse(podcastsByLanguageData) : null;
    if (podcastFeeds) {
      this.setState({
        podcastFeed: podcastFeeds,
        errMessage: ''
      });
    }
  };

  updateLoadingText = () => {
    this.setState((prevState) => {
      const newText = prevState.loadingText === '...' ? '.' : prevState.loadingText + '.';
      return { loadingText: newText };
    });
  };

  loadNext = async () => {
    if (this.state.loading) {
      return;
    }
    if (!this.state.nextPage) {
      return;
    }
    if (this.state.nextLoading || this.state.previousLoading) {
      return;
    }
    this.setState({
      nextLoading: true,
    }, async () => {
      await this.getData();
    });
  };

  loadPrevious = async () => {
    if (this.state.loading) {
      return;
    }
    if (this.state.nextLoading || this.state.previousLoading) {
      return;
    }
    let nextSkip = this.state.nextSkip;
    if (!nextSkip || nextSkip <= 50) {
      return;
    }
    this.setState({
      previousLoading: true,
    }, async () => {
      await this.getData(true);
    });
  };

  scrollToCardById = (id) => {
    const divElement = document.getElementById(`podcastCard-${id}`);
    if (divElement) {
      const rect = divElement.getBoundingClientRect();
      const isInViewPort = rect.top >= 0 && rect.bottom <= window.innerHeight;
      if (!isInViewPort) {
        const targetScrollTop = divElement.offsetTop - (window.innerHeight * 0.5) + (rect.height / 2);
        setTimeout(() => {
          window.scroll({
            top: targetScrollTop,
            left: 0,
            behavior: 'smooth'
          });
        });
      }
    }
  };

  scrollToBottom = () => {
    setTimeout(() => {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'smooth',
      });
    });
  };

  onKeyDown = (e) => {
    const { activeTab, podcastFeed, nextPage, nextSkip, focusNext, focusPrevious } = this.state;
    const maxIndex = podcastFeed.length - 1;
    if (!e.key) {
      return;
    }
    if (e.key === 'ArrowRight') {
      if (activeTab % 2 === 0 && activeTab < maxIndex) {
        this.setState({ activeTab: activeTab + 1 }, () => {
          this.scrollToCardById(this.state.activeTab);
        });
      }
    } else if (e.key === 'ArrowLeft') {
      if (activeTab % 2 !== 0) {
        this.setState({ activeTab: activeTab - 1 }, () => {
          this.scrollToCardById(this.state.activeTab);
        });
      }
    } else if (e.key === 'ArrowDown') {
      if (this.nextButtonRef.current) {
        this.nextButtonRef.current.blur();
      }
      if (this.previousButtonRef.current) {
        this.previousButtonRef.current.blur();
      }
      if (activeTab < maxIndex) {
        this.setState((prev) => {
          const newIndex = Math.min(prev.activeTab + 2, maxIndex);
          setTimeout(() => { this.scrollToCardById(newIndex); });
          return { activeTab: newIndex };
        });
      } else {
        if (nextSkip > 50) {
          if (focusPrevious || nextPage) {
            this.setState({ focusNext: true, focusPrevious: false }, () => {
              if (this.nextButtonRef.current) {
                this.nextButtonRef.current.focus();
              }
            });
          } else if (focusNext || !nextPage) {
            this.setState({ focusPrevious: true, focusNext: false }, () => {
              if (this.previousButtonRef.current) {
                this.previousButtonRef.current.focus();
              }
            });
          }
          this.scrollToBottom();
        } else {
          this.setState({ focusNext: true, focusPrevious: false }, () => {
            if (this.nextButtonRef.current) {
              this.nextButtonRef.current.focus();
            }
          });
          this.scrollToBottom();
        }
      }
    } else if (e.key === 'ArrowUp') {
      if (this.nextButtonRef.current) {
        this.nextButtonRef.current.blur();
      }
      if (this.previousButtonRef.current) {
        this.previousButtonRef.current.blur();
      }
      if (focusNext && nextSkip > 50) {
        this.setState({ focusPrevious: true, focusNext: false }, () => {
          if (this.previousButtonRef.current) {
            this.previousButtonRef.current.focus();
          }
        });
        this.scrollToBottom();
      } else if (activeTab > 0) {
        this.setState({ activeTab: (focusNext || focusPrevious) ? activeTab : activeTab - 2, focusPrevious: false, focusNext: false }, () => {
          this.scrollToCardById(this.state.activeTab);
        });
      }
    } else if (e.key === 'SoftLeft' || e.key === 'Enter') {
      if (focusNext) {
        this.loadNext();
      } else if (focusPrevious) {
        this.loadPrevious();
      } else {
        const data = podcastFeed[activeTab];
        this.props.history.push(`/podcastdetail/${data._id}?`);
      }
    } else if (e.key === 'SoftRight' || e.key === 'Backspace') {
      this.backHandler();
    }
  };

  render() {
    const { selectedLanguage, podcastFeed, activeTab, loading, nextPage, nextSkip, nextLoading, previousLoading, successMessage, errMessage, focusNext, focusPrevious } = this.state;
    return (
      <div>
        <Header title='Podcasts By Language' back={true} backHandler={this.backHandler} />
        {loading ? (
          <PageSpinner />
        ) : (
          <>
            {successMessage.length ? <span style={{ marginTop: 10, color: 'green', fontSize: '0.8rem' }}>{successMessage}</span> : null}
            {errMessage.length ? <span style={{ marginTop: 10, color: 'red', fontSize: '0.8rem' }}>{errMessage}</span> : null}
            <div className={Style.divTitleSection}>
              <div className={Style.divTitleRow}>Podcast By Language</div>
              <div className={Style.divSelectedLanguageRow}>
                {selectedLanguage ? selectedLanguage.name : ''}
              </div>
            </div>

            <PodcastList data={podcastFeed} loading={loading} activeElement={activeTab} canFocus={!focusPrevious && !focusNext} />
            <div style={{ height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {nextSkip > 50 &&
                <span
                  ref={this.previousButtonRef}
                  tabIndex={focusPrevious ? 0 : -1}
                  className={`${Style.button} ${focusPrevious ? Style.focused : ''}`}
                  style={{ cursor: 'pointer', marginRight: '5px' }}
                  onClick={this.loadPrevious}
                >
                  {previousLoading ? `Loading${this.state.loadingText}` : '< Previous'}
                </span>
              }
              {nextPage &&
                <span
                  ref={this.nextButtonRef}
                  tabIndex={focusNext ? 0 : -1}
                  className={`${Style.button} ${focusNext ? Style.focused : ''}`}
                  style={{ cursor: 'pointer' }}
                  onClick={this.loadNext}
                >
                  {nextLoading ? `Loading${this.state.loadingText}` : 'Next >'}
                </span>
              }
            </div>
          </>
        )}
        <div style={{ height: '60px' }}></div>
        <Navigation active='Discover' />
      </div>
    );
  }
}

export default PodcastsByLanguage;
