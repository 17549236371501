import React, { useState, useEffect } from 'react';
import Style from './languageSelector.module.css';

const LanguageSelector = ({ languages, onLanguageSelect, defaultLanguage }) => {
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (defaultLanguage && languages.some(language => language._id === defaultLanguage._id)) {
      const defaultIndex = languages.findIndex(language => language._id === defaultLanguage._id);
      setSelectedLanguage(defaultLanguage);
      setActiveIndex(defaultIndex);
    }
  }, [defaultLanguage, languages]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        const selectedLang = languages[activeIndex];
        setSelectedLanguage(selectedLang);
        onLanguageSelect(selectedLang);
      } else if (event.key === 'ArrowUp') {
        setActiveIndex((prevIndex) => {
          const newIndex = prevIndex > 0 ? prevIndex - 1 : languages.length - 1;
          setSelectedLanguage(languages[newIndex]);
          setTimeout(() => { scrollToCardById(prevIndex); });
          return newIndex;
        });
      } else if (event.key === 'ArrowDown') {
        setActiveIndex((prevIndex) => {
          const newIndex = prevIndex < languages.length - 1 ? prevIndex + 1 : 0;
          setSelectedLanguage(languages[newIndex]);
          setTimeout(() => { scrollToCardById(prevIndex); });
          return newIndex;
        });
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [activeIndex, languages, onLanguageSelect]);

  const scrollToCardById = (id) => {
    setTimeout(() => {
      const divElement = document.getElementById(`languageRow-${id}`);
      if (divElement) {
        window.scroll({
          top: divElement.offsetTop,
          left: 0,
          behavior: 'smooth'
        });
      }
    });
  };

  const handleLanguageChange = (language, index) => {
    setSelectedLanguage(language);
    setActiveIndex(index);
    onLanguageSelect(language);
  };

  return (
    <div className={Style.container}>
      <div className={Style.headerComponent}>
        <div className={Style.titleComponent}>Select a Language</div>
        {languages.map((language, index) => (
          <div
            className={Style.languageRow}
            id={`languageRow-${index}`}
            key={language._id}
            style={{
              backgroundColor: index === activeIndex ? '#c4b5c9' : '#fff',
              cursor: 'pointer'
            }}
            onMouseEnter={() => setActiveIndex(index)}
            onClick={() => handleLanguageChange(language, index)}
          >
            <input
              type='radio'
              id={language._id}
              name='language'
              value={language.name}
              checked={selectedLanguage && selectedLanguage._id === language._id}
              onChange={() => handleLanguageChange(language, index)}
              style={{ display: 'none' }}
            />
            <label htmlFor={language._id} className={Style.label}>
              {language.name}
            </label>
          </div>
        ))}
      </div>
      <div style={{ height: '60px' }}></div>
      <div className={Style.bottomSheetButtons}>
        <span
          className={Style.button}
          style={{ padding: '3px', backgroundColor: '#c4b5c9', cursor: 'pointer', marginRight: '5px' }}
        >
          Select {selectedLanguage ? selectedLanguage.name : ''}
        </span>
      </div>
    </div>
  );
};

export default LanguageSelector;
