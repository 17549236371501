import { app, analytics, logEvent } from './firebaseConfig';

const createDefaultProperties = (user) => {
    if (!user) {
        return {};
    }
    return {
        'Name': user.displayName || '',
        'Email': user.email || '',
        'UserId': user.uid || '',
        'EmailVerified': user.emailVerified || '',
        'PhoneNumber': user.phoneNumber || '',
        'PhotoURL': user.photoURL || '',
        'Source': process.env.REACT_APP_APP_NAME
    };
};

const _logEvent = (eventName, eventParameters) => {
    try {
        logEvent(analytics, eventName, eventParameters);
        console.log(`Event ${eventName} logged successfully.`);
    } catch (error) {
        console.error(`Error logging event: ${eventName}`, error);
    }
};

const logEventAppDownload = (user) => {
    const isDownload = localStorage.getItem('isDownload');
    if (isDownload) {
        return;
    }
    const defaultProperties = createDefaultProperties(user);
    _logEvent('App_Download', defaultProperties);
    localStorage.setItem('isDownload', true);
};

const logEventAppLaunch = (user) => {
    const defaultProperties = createDefaultProperties(user);
    _logEvent('App_Launch', defaultProperties);
};

const logEventLogin = (user) => {
    if (!user) {
        return;
    }
    const defaultProperties = createDefaultProperties(user);
    _logEvent('Login', defaultProperties);
};

const logEventDiscoverTab = (user) => {
    if (!user) {
        return;
    }
    const defaultProperties = createDefaultProperties(user);
    _logEvent('Discover_Tab', defaultProperties);
};

const logEventPodcastsTab = (user) => {
    if (!user) {
        return;
    }
    const defaultProperties = createDefaultProperties(user);
    _logEvent('Podcasts_Tab', defaultProperties);
};

const logEventTopTenPodcastsTab = (user) => {
    if (!user) {
        return;
    }
    const defaultProperties = createDefaultProperties(user);
    _logEvent('Top_Ten_Podcasts_Tab', defaultProperties);
}

const logEventPodcastsByLanguageTab = (user, data) => {
    if (!user) {
        return;
    }
    const defaultProperties = createDefaultProperties(user);
    const eventParameters = { ...defaultProperties, ...data };
    _logEvent('Podcasts_By_Language_Tab', eventParameters);
};

const logEventProfileTab = (user) => {
    if (!user) {
        return;
    }
    const defaultProperties = createDefaultProperties(user);
    _logEvent('Profile_Tab', defaultProperties);
};

const logEventPodcastDetails = (user, podcast) => {
    if (!user) {
        return;
    }
    const defaultProperties = createDefaultProperties(user);
    const eventParameters = { ...defaultProperties, ...podcast };
    _logEvent('Podcast_Details', eventParameters);
};

const logEventBrowseByCategory = (user, categoryName) => {
    if (!user) {
        return;
    }
    const defaultProperties = createDefaultProperties(user);
    const eventParameters = { CategoryName: categoryName, ...defaultProperties };
    _logEvent('Browse_By_Category', eventParameters);
};

const logEventPodcastPlayed = (user, podcast) => {
    if (!user) {
        return;
    }
    const defaultProperties = createDefaultProperties(user);
    const eventParameters = { ...defaultProperties, ...podcast };
    _logEvent('Podcast_Played', eventParameters);
};

export {
    logEventAppDownload,
    logEventAppLaunch,
    logEventLogin,
    logEventDiscoverTab,
    logEventPodcastsTab,
    logEventTopTenPodcastsTab,
    logEventPodcastsByLanguageTab,
    logEventProfileTab,
    logEventPodcastDetails,
    logEventBrowseByCategory,
    logEventPodcastPlayed
};
