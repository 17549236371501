import React, { Component } from 'react';
import { get } from '../../api/api';
import { withRouter } from 'react-router-dom';
import logo from 'assets/logo.png';
import googleLogo from 'assets/google.png';
import guestLogo from 'assets/dummy-profile.png';
import { auth, googleProvider, signInWithPopup, signInWithRedirect } from 'utils/firebaseConfig';
import Style from 'pages/signIn/signInStyles.module.css';
import PageSpinner from 'components/Spinner/PageSpinner';

class SignInPage extends Component {

    constructor(props) {
        super(props);
        this.googleBtn = React.createRef();
        this.state = {
            user: null,
            errorMsg: '',
            activeTab: 0,
        };

        this.onKeyDown = this.onKeyDown.bind(this);
        this.clearError = this.clearError.bind(this);
        this.handleSignIn = this.handleSignIn.bind(this);
        this.handleContinue = this.handleContinue.bind(this);
    }

    async componentDidMount() {
        document.addEventListener('keydown', this.onKeyDown);
        if (!this.state.errorMsg.length && this.googleBtn.current) {
            this.googleBtn.current.focus();
        }
        this.userInterval = setInterval(this.getUser, 2000); // 2000 milliseconds = 2 seconds
        this.unblock = this.props.history.block((location, action) => {
            return action === 'POP' ? false : undefined;
        });
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onKeyDown);
        clearInterval(this.userInterval);
        if (this.unblock) {
            this.unblock();
        }
    }

    getUser = async () => {
        try {
            const response = await get('/user/me');
            const { user } = response;
            if (user) {
                this.setState({ user: user });
                this.props.history.push(`/discover`);
            }
        } catch (error) {
        }
    };

    onKeyDown(e) {
        return;
        const { activeTab, errorMsg } = this.state;
        if (e.key === 'ArrowUp' && activeTab > 0) {
            this.setState({ activeTab: activeTab - 1 });
        } else if (e.key === 'ArrowDown' && activeTab < 2) {
            this.setState({ activeTab: activeTab + 1 });
        } else if (e.key === 'Enter' && errorMsg.length) {
            this.clearError();
        } else if (e.key === 'Backspace' || e.key === 'ArrowRight') {
            this.props.history.goBack();
        }
    }

    async handleSignIn() {
        const { setShowLoader } = this.props;
        try {
            setShowLoader(true);
            googleProvider.addScope('profile');
            if (process.env.REACT_APP_FIREBASE_REDIRECT_URI) {
                googleProvider.setCustomParameters({
                    redirect_uri: process.env.REACT_APP_FIREBASE_REDIRECT_URI,
                });
            }
            await signInWithPopup(auth, googleProvider);
        } catch (err) {
            setShowLoader(false);
            this.setState({ errorMsg: err.message || 'Something went wrong' });
        }
    }

    handleContinue() {
        if (this.unblock) {
            this.unblock();
        }
        this.props.history.push(`/discover`);
    }

    clearError() {
        this.setState({ errorMsg: '' });
    }

    render() {
        const { showLoader } = this.props;
        const { errorMsg, activeTab } = this.state;

        if (showLoader) {
            return <PageSpinner />;
        }

        return (
            <div className={Style.SignInLayout}>
                {errorMsg && (
                    <div className={Style.backdrop} onClick={this.clearError}>
                        <div className={Style.errorWrapper}>
                            <span className={Style.closeIcon} onClick={this.clearError} style={{ border: '1px solid #451e53' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 24 24" width="20">
                                    <path fill="red" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                                </svg>
                            </span>
                            <div className={Style.title}>Error</div>
                            <div className={Style.erMsg}>{errorMsg}</div>
                        </div>
                    </div>
                )}
                <img src={logo} style={{ height: '150px' }} alt="Logo" />
                <div className={Style.btnWrapper}>
                    <button className={Style.button} onClick={this.handleSignIn} ref={this.googleBtn}>
                        <span className={Style.SignInButtonsContainer}>
                            <img src={googleLogo} style={{ height: '20px', marginRight: '5px' }} alt="Sign in with Google" />
                            <span className={Style.btnText}>Sign in with Google</span>
                        </span>
                    </button>
                </div>
                <div className={Style.btnWrapper}>
                    <button className={Style.button} onClick={this.handleContinue} ref={this.googleBtn}>
                        <span className={Style.SignInButtonsContainer}>
                            <img src={guestLogo} style={{ height: '20px', marginRight: '5px' }} alt="Continue as Guest" />
                            <span className={Style.btnText}>Continue as Guest</span>
                        </span>
                    </button>
                </div>
            </div>
        );
    }

}

export default withRouter(SignInPage);