import React, { Component } from 'react';
import { HashRouter, Switch, Route } from 'react-router-dom';
import 'App.css';
import SignInPage from 'pages/signIn/SignInPage';
import Discover from 'pages/discover/discover';
import TopTenPodcasts from 'pages/topTenPodcasts/TopTenPodcasts';
import PodcastsByLanguage from 'pages/podcastsByLanguage/PodcastsByLanguage';
import Podcast from 'pages/podcast/Podcast';
import SettingsPage from 'pages/settings/SettingsPage';
import AddPodcastPage from 'pages/addPodcast/addPodcastPage';
import ProfilePage from 'pages/profile/ProfilePage';
import PodcastDetail from 'pages/podcastDetail/podcastDetail';
import EpisodePage from 'pages/episodePage/episodePage';
import HistoryPage from 'pages/history/HistoryPage';
import TermsAndConditionsPage from 'pages/termsAndConditions/TermsAndConditionsPage';
import PrivacyPolicyPage from 'pages/privacyPolicy/privacyPolicyPage';
import RewardHistoryPage from 'pages/rewardHistory/RewardHistoryPage';
import LinkWallet from 'pages/linkWallet/linkWallet';
import AirDropHistoryPage from 'pages/airDropHistory/AirDropHistoryPage';
import ShopHistoryPage from 'pages/shopHistory/ShopHistoryPage';
import CoinbaseWallet from 'pages/coinbaseWallet/coinbaseWallet';
import MyWallet from 'pages/myWallet/myWallet';
import WebView from 'components/webview/webview';
import NetworkStatus from 'pages/network/NetworkStatus';
import { logEventAppDownload, logEventAppLaunch, logEventLogin } from 'utils/analytics';
import { auth } from 'utils/firebaseConfig';

export const UserContext = React.createContext();

class App extends Component {
  state = {
    user: null,
    showLoader: true,
    isLoginEventTracked: false
  };

  setShowLoader = (value) => {
    this.setState({ showLoader: value });
  };

  setFirebaseUser = async (newUser) => {
    this.setState({ showLoader: false, user: newUser });
    if (!newUser) {
      localStorage.removeItem('accessToken');
    } else {
      try {
        const idToken = await newUser.getIdToken();
        if (!this.state.isLoginEventTracked) {
          logEventLogin(newUser);
          this.setState({ isLoginEventTracked: true });
        }
        localStorage.setItem('accessToken', idToken);
      } catch (error) {
        console.error('Error setting access token:', error);
        localStorage.removeItem('accessToken');
      }
    }
  };

  async componentDidMount() {
    logEventAppDownload(this.state.user);
    logEventAppLaunch(this.state.user);
    setInterval(() => {
      this.unsubscribe = auth.onAuthStateChanged(async (userObj) => {
        try {
          await this.setFirebaseUser(userObj || null);
        } catch (error) {
          console.error('Error setting user:', error);
          await this.setFirebaseUser(null);
        }
      });
    }, 2000); // 2000 milliseconds = 2 seconds
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  renderRoutes() {
    return (
      <Switch>
        <Route exact path='/'>
          <SignInPage showLoader={this.state.showLoader} setShowLoader={this.setShowLoader} />
        </Route>
        <Route exact path='/discover' component={Discover} />
        <Route exact path='/home/topTenPodcasts' component={TopTenPodcasts} />
        <Route exact path='/home/podcastsByLanguage' component={PodcastsByLanguage} />
        <Route exact path='/home/podcast' component={Podcast} />
        <Route exact path='/settings' component={SettingsPage} />
        <Route exact path='/addPodcast' component={AddPodcastPage} />
        <Route exact path='/home/profile' component={ProfilePage} />
        <Route path='/podcastdetail/:id' component={PodcastDetail} />
        <Route exact path='/episodePage/:id/:episode' component={EpisodePage} />
        <Route exact path='/history' component={HistoryPage} />
        <Route exact path='/rewardHistory' component={RewardHistoryPage} />
        <Route exact path='/home/termsAndConditions' component={TermsAndConditionsPage} />
        <Route exact path='/home/privacyPolicy' component={PrivacyPolicyPage} />
        <Route exact path='/shopHistory' component={ShopHistoryPage} />
        <Route exact path='/airDropHistory' component={AirDropHistoryPage} />
        <Route exact path='/linkwallet' component={LinkWallet} />
        <Route exact path='/webview/:url?' component={WebView} />
        <Route exact path='/coinbaseWallet' component={CoinbaseWallet} />
        <Route exact path='/mywallet/:code?' component={MyWallet} />
      </Switch>
    );
  }

  render() {
    return (
      <UserContext.Provider value={this.state.user}>
        <div className='App' style={{ position: 'relative' }}>
          <HashRouter>
            <NetworkStatus />
            {this.renderRoutes()}
          </HashRouter>
        </div>
      </UserContext.Provider>
    );
  }

}

export default App;
