import { auth } from 'utils/firebaseConfig';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_SERVER_URL;
const axiosInstance = axios.create({
    baseURL: BASE_URL,
});

let currentAccessToken = localStorage.getItem('accessToken');

axiosInstance.interceptors.request.use(
    async (config) => {
        const userObj = auth.currentUser;
        if (userObj) {
            const idToken = await userObj.getIdToken();
            if (idToken !== currentAccessToken) {
                localStorage.setItem('accessToken', idToken);
                currentAccessToken = idToken;
            }
        } else {
            localStorage.removeItem('accessToken');
            currentAccessToken = null;
        }
        if (currentAccessToken) {
            config.headers['Authorization'] = `Bearer ${currentAccessToken}`;
        }
        config.headers['x-device'] = 'kaiOSApp';
        config.headers['x-device-app-version'] = 2;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export const get = async (endpoint, params = {}) => {
    try {
        const response = await axiosInstance.get(endpoint, { params });
        return response.data;
    } catch (error) {
        throw error.response.data.message || 'Something went wrong';
    }
};

export const post = async (endpoint, data = {}) => {
    try {
        const response = await axiosInstance.post(endpoint, data);
        return response.data;
    } catch (error) {
        throw error.response.data.message || 'Something went wrong';
    }
};

export const patch = async (endpoint, data = {}) => {
    try {
        const response = await axiosInstance.patch(endpoint, data);
        return response.data;
    } catch (error) {
        throw error.response.data.message || 'Something went wrong';
    }
};
