import React, { Component } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import ProgressBar from '@ramonak/react-progress-bar';
import { get, post } from '../../api/api';
import PageSpinner from 'components/Spinner/PageSpinner';
import { logEventPodcastPlayed } from 'utils/analytics';
import back from 'assets/back.png';
import pauseButton from 'assets/pause-button.png';
import forward from 'assets/forward.png';
import backward from 'assets/backward.png';
import playButton from 'assets/play.png';
import volumeUp from 'assets/volume-up.png';
import volumeDown from 'assets/volume-down.png';
import like from 'assets/heart-color.png';
import unlike from 'assets/heart-black.png';
import Style from './episodePage.module.css';
import defaultImage from 'assets/logo_short.png';
import './style.scss';

class EpisodePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            podcast: null,
            episode: null,
            likes: 0,
            playStatus: false,
            likeStatus: false,
            currentPlayTime: 0,
            mediaLength: 0,
            loadingText: '',
            errorMessage: ''
        };
        this.audioPlayer = React.createRef();
        this.loadingAnimation = null;
    }

    async componentDidMount() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        await this.fetchUser();
        await this.getEpisodeAndPodcast();
    }

    componentWillUnmount() {
        if (this.loadingAnimation) clearInterval(this.loadingAnimation);
    }

    fetchUser = async () => {
        try {
            const { user } = await get('user/me');
            this.setState({ user });
        } catch (error) {
            console.error('Error fetching user data', error);
        }
    };

    getEpisodeAndPodcast = async () => {
        const { id, episode: episodeId } = this.props.match.params;
        try {
            const response = await get(`podcasts/getEpisode/${id}/${episodeId}`);
            const { podcast, episode } = response;
            const duration = parseInt(episode.duration || 0, 10);
            this.setState({ podcast, episode, mediaLength: duration });
            logEventPodcastPlayed(this.state.user, {
                PodcastId: id,
                EpisodeId: episodeId,
                Category: podcast.categories || '',
                PodcastName: podcast.title || '',
                Author: podcast.author || '',
                MediaURL: episode.url
            });
        } catch (error) {
            console.error('Error fetching episode and podcast data', error);
        }
    };

    volumeUpDown = (action) => {
        if (navigator && navigator.volumeManager) {
            navigator.volumeManager[`request${action.charAt(0).toUpperCase() + action.slice(1)}`]();
        }
    };

    playPause = () => {
        const { playStatus, mediaLength } = this.state;
        const audio = this.audioPlayer.current.audioEl.current;
        if (audio && !mediaLength) {
            this.setState({ mediaLength: Math.floor(audio.duration || 0) });
        }
        if (playStatus) {
            audio.pause();
        } else {
            audio.play();
        }
        this.setState({ playStatus: !playStatus });
    };

    updateLoadingText = () => {
        this.setState((prevState) => ({
            loadingText: prevState.loadingText.length === 3 ? '.' : prevState.loadingText + '.'
        }));
    };

    likeHandler = async () => {
        if (!this.state.user) {
            this.setState({ errorMessage: 'Please sign in to like the episode' });
            setTimeout(() => this.setState({ errorMessage: '' }), 5000);
            return;
        }
        try {
            this.loadingAnimation = setInterval(this.updateLoadingText, 500);
            const body = { _id: this.state.episode._id };
            await post('podcasts/likeUnlikeEpisode', body);
            this.setState(prevState => ({
                likeStatus: !prevState.likeStatus,
                likes: prevState.likeStatus ? prevState.likes - 1 : prevState.likes + 1,
                loadingText: ''
            }));
        } catch (error) {
            console.error('Error like / unlike episode', error);
            this.setState({ loadingText: '' });
        } finally {
            if (this.loadingAnimation) clearInterval(this.loadingAnimation);
        }
    };

    onLoadedMetadata = (e) => {
        const duration = Math.floor(e.target.duration || 0)
        if (duration) {
            this.setState({ mediaLength: duration });
        }
    };

    onListen = () => {
        this.setState(prevState => ({ currentPlayTime: prevState.currentPlayTime + 1 }));
    };

    jump = (direction) => {
        const { currentPlayTime, mediaLength } = this.state;
        const seconds = direction === 'forward' ? 5 : -5;
        const newPlayTime = Math.max(0, Math.min(mediaLength, currentPlayTime + seconds));
        this.audioPlayer.current.audioEl.current.currentTime = newPlayTime;
        this.setState({ currentPlayTime: newPlayTime });
    };

    formatTime = (time) => {
        if (isNaN(time)) return '00:00';
        const minutes = String(Math.floor(time / 60)).padStart(2, '0');
        const seconds = String(Math.floor(time % 60)).padStart(2, '0');
        return `${minutes}:${seconds}`;
    };

    render() {
        const { playStatus, episode, likeStatus, likes, currentPlayTime, mediaLength, loadingText, errorMessage } = this.state;
        const innerHeight = window.innerHeight;
        const innerWidth = window.innerWidth;

        return (
            <div className={Style.container} style={{ height: innerHeight, width: innerWidth }}>
                {!episode ? (
                    <PageSpinner />
                ) : (
                    <>
                        <div className={Style.headerComponent}>
                            {errorMessage && <span style={{ marginTop: 10, color: 'red', fontSize: '0.8rem' }}>{errorMessage}</span>}
                            <div className={Style.backButton}>
                                <img src={back} alt='back' style={{ marginLeft: '10px', height: '20px', width: '20px' }} onClick={this.props.history.goBack} />
                                <span className={Style.episodeTitle}> {episode.title}</span>
                            </div>
                            <div className={Style.podcastImage}>
                                <img src={episode.thumbnailURL80x80 || defaultImage} alt='podcastImage' className={Style.podcastBanner} />
                            </div>
                            <div className={Style.likeButton} onClick={this.likeHandler}>
                                <span>
                                    <img src={likeStatus ? like : unlike} alt='' className={Style.likeImage} />
                                    <span>{likes || ''} Like {loadingText}</span>
                                </span>
                            </div>
                            <div>
                                {playStatus ? 'not playing' : 'now playing'}
                                <br />
                                {currentPlayTime}
                                <br />
                                {mediaLength}
                                <br />
                                {this.formatTime(mediaLength)}
                                <br />
                                {this.audioPlayer ? 'Y' : 'N'}
                            </div>
                        </div>
                        <div className={Style.mediaContainer}>
                            <div className={Style.headerComponent}>
                                <div className={Style.controlContainer}>
                                    {episode.url && (
                                        <>
                                            <div className={Style.playerWrapper} onClick={() => this.volumeUpDown('down')}>
                                                <img src={volumeDown} className={Style.volumeButton} alt='volume down' />
                                            </div>
                                            <div className={Style.playerWrapper} onClick={() => this.jump('backward')}>
                                                <img src={backward} className={Style.playButton} alt='backward' />
                                            </div>
                                            <div className={Style.playerWrapper} onClick={this.playPause}>
                                                <img src={playStatus ? pauseButton : playButton} className={Style.playButton} alt='play/pause' />
                                            </div>
                                            <div className={Style.playerWrapper} onClick={() => this.jump('forward')}>
                                                <img src={forward} className={Style.playButton} alt='forward' />
                                            </div>
                                            <div className={Style.playerWrapper} onClick={() => this.volumeUpDown('up')}>
                                                <img src={volumeUp} className={Style.volumeButton} alt='volume up' />
                                            </div>
                                        </>
                                    )}
                                </div>
                                {episode.url && (
                                    <div className={Style.progressBarContainer}>
                                        <span className={Style.duration}>{this.formatTime(currentPlayTime)}</span>
                                        <ProgressBar
                                            className='progressBarWrapper'
                                            customLabel=' '
                                            completed={Math.round((currentPlayTime * 100) / mediaLength)}
                                            bgColor='#442253'
                                            height='5px'
                                            width='100%'
                                        />
                                        <span className={Style.duration}>{this.formatTime(mediaLength)}</span>
                                    </div>
                                )}
                                {episode.url && (
                                    <ReactAudioPlayer
                                        ref={this.audioPlayer}
                                        src={episode.url}
                                        autoPlay={false}
                                        listenInterval={1000}
                                        onListen={this.onListen}
                                        onLoadedMetadata={this.onLoadedMetadata}
                                    />
                                )}
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    }
}

export default EpisodePage;