import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';

import discover from 'assets/discover.png';
import podcast from 'assets/podcast.png';
import profile from 'assets/profile.png';
import podcastActive from 'assets/active-note.png';
import discoverActive from 'assets/active-discover.png';
import profileActive from 'assets/active-profile.png';
import Style from './navigation.module.css';

const tabs = [{
  route: '/discover',
  label: 'Discover',
  key: '1',
  icon: discover,
  active: discoverActive,
  tooltip: 'Press 1 to open Discover'
}, {
  route: '/home/podcast',
  label: 'Podcast',
  key: '2',
  icon: podcast,
  active: podcastActive,
  tooltip: 'Press 2 to open Podcast'
}, {
  route: '/home/profile',
  label: 'Profile',
  key: '3',
  icon: profile,
  active: profileActive,
  tooltip: 'Press 3 to open Profile'
}];

const Navigation = (props) => {
  const navigate = useHistory();
  const handleRoute = (route) => { navigate.push(route); };

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => { document.removeEventListener('keydown', onKeyDown); };
  }, []);

  const onKeyDown = (e) => {
    if (e.key === '1') {
      if (props.active !== 'Discover') {
        navigate.push('/discover');
      }
    } else if (e.key && e.key === '2') {
      if (props.active !== 'Podcast') {
        navigate.push('/home/podcast');
      }
    } else if (e.key && e.key === '3') {
      if (props.active !== 'Profile') {
        navigate.push('/home/profile');
      }
    }
  };

  return (
    <>
      <nav className={Style.navContainer}>
        <div className={Style.navDiv}>
          {
            tabs.map((tab, index) => (
              <div className={Style.paddingEven} title={tab.tooltip} key={index} onClick={() => { handleRoute(tab.route); }} style={{ cursor: 'pointer' }}>
                <div className={Style.iconStyling}>
                  <div className={Style.iconWrapper}>
                    <svg strokeWidth='0' viewBox='0 0 24 24' height='25px' width='25px' xmlns='http://www.w3.org/2000/svg'>
                      {tab.label === 'Discover' ? <path fill={props.active === tab.label ? '#451e53' : '#c4b5c9'} d='M6 13c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm0 4c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm0-8c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm-3 .5c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zM6 5c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm15 5.5c.28 0 .5-.22.5-.5s-.22-.5-.5-.5-.5.22-.5.5.22.5.5.5zM14 7c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm0-3.5c.28 0 .5-.22.5-.5s-.22-.5-.5-.5-.5.22-.5.5.22.5.5.5zm-11 10c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm7 7c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm0-17c.28 0 .5-.22.5-.5s-.22-.5-.5-.5-.5.22-.5.5.22.5.5.5zM10 7c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm0 5.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm8 .5c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm0 4c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm0-8c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm0-4c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm3 8.5c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zM14 17c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm0 3.5c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm-4-12c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm0 8.5c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm4-4.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm0-4c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5z' /> :
                        tab.label === 'Podcast' ? <path fill={props.active === tab.label ? '#451e53' : '#c4b5c9'} d='M12 3v10.55c-.59-.34-1.27-.55-2-.55-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4V7h4V3h-6z' /> :
                          tab.label === 'Profile' ? <path fill={props.active === tab.label ? '#451e53' : '#c4b5c9'} d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z' /> : <div></div>}
                    </svg>
                  </div>
                  {/* <img src={props.active===tab.label?tab.active:tab.icon} style={{ height: '25px' }} alt='tab' /> */}
                  <div className={Style.iconLabel} style={{ color: props.active === tab.label ? '#451e53' : '#c4b5c9' }}>{tab.label} ({tab.key})</div>
                </div>
              </div>
            ))
          }
        </div>
      </nav>
    </>
  );
};

export default Navigation;
