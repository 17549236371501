import React, { Component } from 'react';
import PageSpinner from 'components/Spinner/PageSpinner';
import Header from 'components/Header/Header';
import { get, post } from '../../api/api';
import Style from 'pages/addPodcast/addPodcastStyles.module.css';
import InputBox from 'components/InputBox/InputBox';
import Button from 'components/Button/Button';

class AddPodcastPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      user: null,
      rssURL: '',
      activeTab: 0,
      loading: true,
      loadingText: '',
      loadingAnimation: null,
      successMessage: '',
      errorMessage: '',
    };
  }

  async componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    document.addEventListener('keydown', this.onKeyDown);
    await this.fetchUser();
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
  }

  fetchUser = async () => {
    try {
      const response = await get('user/me');
      this.setState({
        user: response.user,
        errorMessage: '',
        loading: false
      });
      if (!response.user) {
        this.setState({ errorMessage: 'Please signin to add podcast' });
        setTimeout(() => {
          this.setState({ errorMessage: '' });
        }, 5000);
      }
    } catch (error) {
      this.setState({
        errorMessage: error || 'Error fetching user data',
        loading: false
      });
    }
  };

  onKeyDown = (e) => {
    if (!e.key) {
      return;
    }
    if (e.key === 'SoftRight' || e.key === 'ArrowLeft') {
      this.props.history.push('/home/profile');
    } else if (e.key === 'ArrowDown') {
      this.setState((prevState) => ({
        activeTab: prevState.activeTab < 1 ? prevState.activeTab + 1 : prevState.activeTab,
      }));
    } else if (e.key === 'ArrowUp') {
      this.setState((prevState) => ({
        activeTab: prevState.activeTab > 0 ? prevState.activeTab - 1 : prevState.activeTab,
      }));
    } else if (e.key === 'Enter') {
      this.submitPodcastHandler();
    } else if (e.key === 'Backspace') {
      if (this.state.activeTab !== 0) {
        this.props.history.goBack();
      }
    }
  };

  backHandler = () => {
    this.props.history.push('/home/profile');
  };

  rssURLChangeHandler = (event) => {
    this.setState({
      successMessage: '',
      errorMessage: '',
      activeTab: 0,
      rssURL: event.target.value,
    });
    if (!event.target.value) {
      this.setState({ errorMessage: 'Please enter RSS URL' });
    }
  };

  updateLoadingText = () => {
    this.setState((prevState) => {
      const newText = prevState.loadingText === '...' ? '.' : prevState.loadingText + '.';
      return { loadingText: newText };
    });
  };

  submitPodcastHandler = async () => {
    this.setState({ successMessage: '', errorMessage: '' });
    const { rssURL, user } = this.state;
    if (!user) {
      this.setState({ errorMessage: 'Please sign in to add podcast' });
      return;
    }
    if (!rssURL) {
      this.setState({ errorMessage: 'Please enter RSS URL' });
      return;
    }
    try {
      this.loadingAnimation = setInterval(this.updateLoadingText, 500);
      const body = { rssURL: rssURL };
      await post('podcasts/add', body);
      this.setState({
        successMessage: 'Successfully added',
        errorMessage: '',
        loadingText: ''
      });
      if (this.loadingAnimation) {
        clearInterval(this.loadingAnimation);
      }
    } catch (e) {
      this.setState({
        successMessage: '',
        errorMessage: e || 'Something went wrong',
        loadingText: '',
      });
      if (this.loadingAnimation) {
        clearInterval(this.loadingAnimation);
      }
    }
  };

  render() {
    const { user, rssURL, loading, successMessage, errorMessage, activeTab, loadingText } = this.state;
    return (
      <>
        <Header title='Add Podcast' back={true} backHandler={this.backHandler} />
        <div className={Style.headerComponent}>
          {loading ? (
            <PageSpinner />
          ) : (
            <div className={Style.AddPodcastLayout}>
              {successMessage.length ? <span style={{ marginTop: '5px', marginBottom: '5px', color: 'green', fontSize: '0.8rem' }}>{successMessage}</span> : <></>}
              {errorMessage.length ? <span style={{ marginTop: '5px', marginBottom: '5px', color: 'red', fontSize: '0.8rem' }}>{errorMessage}</span> : <></>}
              <span style={{ fontWeight: 'bold' }}>Add RSS URL</span>
              <span className={Style.PodcastInput}>
                <InputBox inputValue={rssURL} inputChangeHandler={this.rssURLChangeHandler} isFocus={activeTab === 0} />
              </span>
              <div className={Style.PodcastButton}>
                <Button buttonText={`Submit Podcast${loadingText}`} buttonOnClick={this.submitPodcastHandler} isFocus={activeTab === 1 || rssURL} disabled={!rssURL || loadingText} />
              </div>
            </div>
          )
          }
        </div>
      </>
    );
  }
}

export default AddPodcastPage;
