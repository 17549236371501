import React, { Component } from 'react';
import Header from 'components/Header/Header';
import { get } from '../../api/api';
import Style from 'pages/profile/profileStyles.module.css';
import MenuButton from 'components/MenuButton/MenuButton';
import { colors } from 'utils/colors';
import Navigation from 'navigation/navigation';
import { withRouter } from 'react-router-dom';
import placeHolderImg from 'assets/dummy-profile.png';
import { logEventProfileTab } from 'utils/analytics';

class ProfilePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            displayData: { following: 0, points: 0 },
            activeTab: 0
        };
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    componentDidMount() {
        this.fetchData();
        document.addEventListener('keydown', this.onKeyDown);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.activeTab !== this.state.activeTab) {
            document.addEventListener('keydown', this.onKeyDown);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onKeyDown);
    }

    async fetchData() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        try {
            const response = await get('/user/me');
            const { user: userObj } = response;
            if (userObj) {
                this.setState({
                    user: userObj,
                    displayData: { following: userObj.followCount || 0, points: userObj.totalPoints || 0 }
                });
            }
        } catch (err) {
        }
        logEventProfileTab(this.state.user);
    }

    onKeyDown(e) {
        return;
        if (!e.key) {
            return;
        }
        const { history } = this.props;
        const { activeTab } = this.state;

        switch (e.key) {
            case 'SoftRight':
                history.goBack();
                break;
            case 'ArrowDown':
                if (activeTab < 1) {
                    this.setState(prevState => {
                        setTimeout(() => { this.scrollToMenuButton(prevState.activeTab + 1); });
                        return { activeTab: prevState.activeTab + 1 };
                    });
                } else {
                    window.scrollTo({ bottom: 0, behavior: 'smooth' });
                }
                break;
            case 'ArrowUp':
                if (activeTab > 0) {
                    this.setState(prevState => {
                        setTimeout(() => { this.scrollToMenuButton(prevState.activeTab - 1); });
                        return { activeTab: prevState.activeTab - 1 };
                    });
                } else {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }
                break;
            case 'Enter':
            case 'SoftLeft':
                if (activeTab === 0) {
                    this.navigationHandler('/addpodcast');
                } else if (activeTab === 1) {
                    this.navigationHandler('/settings');
                }
                break;
            case 'Backspace':
                history.goBack();
                break;
            default:
                break;
        }
    }

    navigationHandler(url) {
        this.props.history.push(`${url}`);
    }

    scrollToMenuButton(id) {
        setTimeout(() => {
            const menuButtonElement = document.getElementById(`menuButton-${id}`);
            if (menuButtonElement) {
                window.scroll({
                    top: menuButtonElement.offsetTop,
                    left: 0,
                    behavior: 'smooth'
                });
            }
        });
    }

    render() {
        const { user, displayData, activeTab } = this.state;

        return (
            <>
                <Header title="Profile" />
                <div className={Style.headerComponent}>
                    <div className={Style.container} id="profile">
                        <div className={Style.ProfileCard}>
                            <div className={Style.RoundCard}>
                                <img src={user?.photoURL || placeHolderImg} className={Style.ProfileImage} alt='' />
                            </div>
                            <div>
                                <p className={Style.NameStyle}>{user?.displayName || 'Guest'}</p>
                                <p className={Style.EmailStyle}>{user?.email || ''}</p>
                            </div>
                            <hr className={Style.HrStyle} />
                            <div className={Style.Info}>
                                <div className={Style.InfoDetail}>
                                    <div className={Style.Bold}>{displayData.following || 0}</div>
                                    <span>Following</span>
                                </div>
                                <div style={{ width: '5px' }}></div>
                                <div className={Style.InfoDetail}>
                                    <div className={Style.Bold}>{displayData.points || 0}</div>
                                    <span>SHEQ tokens</span>
                                </div>
                            </div>
                        </div>
                        <MenuButton
                            index={1}
                            buttonText='Add Podcast'
                            iconBgColor={colors.ICON_BACKGROUND_PURPLE}
                            iconColor={colors.ICON_PURPLE}
                            onClick={() => { this.navigationHandler('/addpodcast'); }}
                            isFocus={activeTab === 0}
                        >
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1.5em" width="1.5em" xmlns="http://www.w3.org/2000/svg">
                                <path fill="none" stroke="#000" strokeWidth="2" d="M12,18 L12,6 M6,12 L18,12"></path>
                            </svg>
                        </MenuButton>
                        <hr className={Style.HrStyle} />
                        <MenuButton
                            index={2}
                            buttonText='Settings'
                            iconBgColor={colors.ICON_BACKGROUND_PURPLE}
                            iconColor={colors.ICON_PURPLE}
                            onClick={() => { this.navigationHandler('/settings'); }}
                            isFocus={activeTab === 1}
                        >
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1.5em" width="1.5em" xmlns="http://www.w3.org/2000/svg">
                                <path d="M413.967 276.8c1.06-6.235 1.06-13.518 1.06-20.8s-1.06-13.518-1.06-20.8l44.667-34.318c4.26-3.118 5.319-8.317 2.13-13.518L418.215 115.6c-2.129-4.164-8.507-6.235-12.767-4.164l-53.186 20.801c-10.638-8.318-23.394-15.601-36.16-20.801l-7.448-55.117c-1.06-4.154-5.319-8.318-10.638-8.318h-85.098c-5.318 0-9.577 4.164-10.637 8.318l-8.508 55.117c-12.767 5.2-24.464 12.482-36.171 20.801l-53.186-20.801c-5.319-2.071-10.638 0-12.767 4.164L49.1 187.365c-2.119 4.153-1.061 10.399 2.129 13.518L96.97 235.2c0 7.282-1.06 13.518-1.06 20.8s1.06 13.518 1.06 20.8l-44.668 34.318c-4.26 3.118-5.318 8.317-2.13 13.518L92.721 396.4c2.13 4.164 8.508 6.235 12.767 4.164l53.187-20.801c10.637 8.318 23.394 15.601 36.16 20.801l8.508 55.117c1.069 5.2 5.318 8.318 10.637 8.318h85.098c5.319 0 9.578-4.164 10.638-8.318l8.518-55.117c12.757-5.2 24.464-12.482 36.16-20.801l53.187 20.801c5.318 2.071 10.637 0 12.767-4.164l42.549-71.765c2.129-4.153 1.06-10.399-2.13-13.518l-46.8-34.317zm-158.499 52c-41.489 0-74.46-32.235-74.46-72.8s32.971-72.8 74.46-72.8 74.461 32.235 74.461 72.8-32.972 72.8-74.461 72.8z"></path>
                            </svg>
                        </MenuButton>
                        <div style={{ height: '60px' }} />
                    </div>
                </div>
                <Navigation active="Profile" />
            </>
        );
    }
}

export default withRouter(ProfilePage);
