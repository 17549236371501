import React, { useEffect, useState } from "react";
import Style from './PodcastCard.module.css';
import { useHistory } from 'react-router-dom';
import defaultImage from 'assets/logo_short.png';
import PageSpinner from 'components/Spinner/PageSpinner';

const PodcastCard = ({ data, activeElement, index, canFocus }) => {
    const navigation = useHistory();
    const [thumbnailURL, setThumbnailURL] = useState('');
    const [loading, setLoading] = useState(true);

    const isActive = activeElement === index;

    useEffect(() => {
        setLoading(true);
        setThumbnailURL(data.thumbnailURL80x80 || defaultImage);
        setLoading(false);
    }, [data]);

    const navigate = () => {
        navigation.push(`/podcastdetail/${data._id}?`);
    };

    return (
        <div id={`podcastCard-${index}`} className={Style.cardBody} onClick={navigate} style={{ cursor: 'pointer' }}>
            {loading ? <PageSpinner /> :
                <img src={thumbnailURL} style={{ height: 'auto', width: '100%' }} alt='podcast' />
            }
            <div className={Style.cardTitle}>{data.title}</div>
        </div>
    );
};

export default PodcastCard;
