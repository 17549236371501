import React, { useState, useEffect } from 'react';
import warning from '../../assets/warning.png';

function NetworkStatus() {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  useEffect(() => {
    const updateOnlineStatus = () => {
      setIsOnline(window.navigator.onLine);
    };

    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, []);

  // console.log('isOnline:', isOnline); // For debugging

  return (
    <div style={{ position: 'absolute', top: 0, width: '100%', zIndex: 9999 }}>
      {!isOnline && (
        <div style={{ backgroundColor: '#FF0000', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5px' }}>
          <img src={warning} style={{ height: '20px', width: '20px' }} alt='warning' />
          <span style={{ color: '#ffffff', fontWeight: '500', fontSize: '14px', marginLeft: '5px' }}>
            No Internet Connection Available
          </span>
        </div>
      )}
    </div>
  );
}

export default NetworkStatus;
